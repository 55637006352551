import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  // Box,
  Button,
  // CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function DynamicModal({
  open,
  setOpen,
  data,
  setData,
  selectedData,
  selectedIndex,
  setSelectedData,
}) {
  const [menuInputs, setMenuInputs] = useState({
    title: "",
    description: "",
    image: "",
  });

  console.log(selectedIndex, "staydfastyfg");

  const handleChange = (e) => {
    setMenuInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    if(selectedData){
      setMenuInputs({...selectedData });
    }
    
  }, [selectedData]);

  const handleImageUpload = (e) => {
    // const file = e.target.files[0];
    // console.log(e.target.files[0], "usatdfyusf");
    setMenuInputs((prev) => {
      return {
        ...prev,
        local_image: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      };
    });
  };

  const handleAddData = () => {
    let newArr = data.menu_items;
    newArr.push(menuInputs);
    console.log(newArr, data, menuInputs, "cjajjajja");
    setData({ ...data, menu_items: [...newArr] });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData({ image: "", title: "", description: "" });
  };
  const handleEditData = () => {
    let newArr = data.menu_items;
    newArr[selectedIndex] = menuInputs;
    setData({ ...data, menu_items: newArr });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {selectedData?.image || selectedData?.title || selectedData?.description
                ? "Edit Dynamic Website Menu Items "
                : "Add Dynamic Website Menu Items"
                }
            </p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form className="gap-3 mt-3">
                  <TextField
                    label="title"
                    className="pb-3"
                    required
                    style={{ width: "48%" }}
                    onChange={(e) => handleChange(e)}
                    value={menuInputs?.title}
                    name="title"
                  />
                  <p>Enter Description</p>
                  <GeneralCkeditor
                    inputs={menuInputs}
                    value={menuInputs?.description}
                    name="description"
                    setInputs={setMenuInputs}
                    onChange={handleChange}
                  />

                  <div className="d-flex flex-column justify-content-center align-items-center text-align-center mt-4">
                    <img
                      style={{ width: "15rem", height: "8rem" }}
                      src={
                        menuInputs.local_image
                          ? menuInputs?.local_image
                          : s3baseUrl + "/" + selectedData?.image
                      }
                      alt="select pic for preview"
                    />
                    <label htmlFor="icon-button-file-2" className="mt-3">
                      <Input
                        style={{ display: "none" }}
                        accept="image/*"
                        id="icon-button-file-2"
                        type="file"
                        name="image"
                        onChange={(e) => {
                          handleImageUpload(e);
                        }}
                      />
                      <Button
                        aria-label="upload picture"
                        component="span"
                        className="upload-button mt-3"
                      >
                        {selectedData?.image !== ""
                          ? "Change Image"
                          : " Upload Image"}
                      </Button>
                    </label>
                  </div>

                  <div className="text-end">
                    {selectedData?.image || selectedData?.title || selectedData?.description ? (
                      <>
                        <Button variant="contained" onClick={handleEditData}>
                          Edit Data
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button variant="contained" onClick={handleAddData}>
                          Add Data
                        </Button>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
