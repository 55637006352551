// material
import {
  Box,
  Grid,
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Stack,
  Card,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import CustomDashboardCard from "src/components/_dashboard/app/CustomDashboardCard";
import { _admin_dashboard } from "src/DAL/login/login";
// components
import Page from "../components/Page";
import {
  SuccessCard,
  ErrorCard,
  InfoCard,
  WarningCard,
} from "../components/_dashboard/app";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAppContext } from "src/Hooks/AppContext";
import { _support_tickets_listing } from "src/DAL/support_tickets/support_tickets";
import { get_short_string } from "src/utils/utils";
// import { Label } from "@mui/icons-material";
import Label from "../components/Label";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  const { dashboard, setDashboard, isLoading, setIsLoading, setOpenCount } =
    useAppContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openTicketData, setopenTicketData] = useState([]);
  // const [page, pagechange] = useState(0);
  // const [rowperpage, rowperpagechange] = useState(5);
  console.log(openTicketData, "sidebarData");

  // const handlePageChange = (e, newPage) => {
  //   pagechange(newPage);
  // };

  // const handleRowPerPage = (e) => {
  //   rowperpagechange(e.target.value);
  //   pagechange(0);
  // };

  const SideBarListing = async () => {
    const postData = {
      status: "open",
    };
    let resp = await _support_tickets_listing(0, 50, postData);
    setopenTicketData(
      resp.support_ticket.filter((val) => val.response_status === 0)
    );
    setOpenCount(
      resp.support_ticket.filter((val) => val.response_status === 0)
    );
  };

  const get_dashboard_data = async () => {
    const resp = await _admin_dashboard();

    if (resp.code == 200) {
      const _dashboard = resp.Dashboard;
      console.log(_dashboard, "_dashboard123");
      setDashboard(_dashboard);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_dashboard_data();
  }, []);

  useEffect(() => {
    SideBarListing();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const HeadData = [
    { id: "id", name: "#" },
    { id: "subject", name: "Subject" },
    { id: "description", name: "Description" },
    { id: "category", name: "Category" },
    { id: "customers", name: "Customers" },
    { id: "messages", name: "Messages" },
    { id: "response", name: "Response Status" },
    { id: "ticket ", name: "Ticket Status" },
    { id: "lastActivity", name: "Last Activity" },
    { id: "createdAt", name: "Created At" },
    { id: "status", name: "Status" },
  ];

  const CheckActivityTime = (last_action_date) => {
    var No_Response_Threshold = new Date();
    No_Response_Threshold.setDate(No_Response_Threshold.getDate() - 7);
    var last_action = new Date(last_action_date);

    if (
      No_Response_Threshold.getTime() >= last_action.getTime(last_action_date)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRowClick = (row) => {
    navigate("/support-tickets/" + row._id);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <SuccessCard
              label="Total Customers"
              count={dashboard.user_count}
              icon="clarity:users-line"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Inovate Customers"
              count={dashboard.accelerator_user_count}
              icon="clarity:users-solid-alerted"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <WarningCard
              label="Vission Customers"
              count={dashboard.vission_user_count}
              icon="clarity:users-outline-alerted"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Total Projects"
              count={dashboard.project_count}
              icon="el:website"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <SuccessCard
              label="Published Projects"
              count={dashboard.published_project_count}
              icon="entypo:publish"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <ErrorCard
              label="SSL Configured Projects"
              count={dashboard.ssl_configured_project_count}
              icon="fa:expeditedssl"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Help Article Categories"
              count={dashboard.helping_article_category_count}
              icon="carbon:collapse-categories"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Help Articles"
              count={dashboard.helping_article_count}
              icon="ooui:articles-rtl"
            />
          </Grid>
        </Grid>
      </Container>
      <div className="container">
        <Stack mb={3} mt={4}>
          <Typography className="mx-auto " variant="h5" gutterBottom>
            Open Support Waiting Tickets
          </Typography>
        </Stack>

        <Card>
          <>
            <TableContainer sx={{ minWidth: 900 }}>
              <Table style={{ width: "105%", overflow: "auto" }}>
                <TableHead>
                  <TableRow>
                    {HeadData.map((data) => {
                      return (
                        <TableCell key={data.id} className="fw-bold">
                          {data.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {openTicketData.map((row, i) => {
                    const {
                      _id,
                      subject,
                      description,
                      ticket_status,
                      response_status,
                      last_action_date,
                      comment_badge_count,
                      status,
                      department,
                      createdAt,
                      user_id,
                    } = row;
                    // console.log(row)

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                        <TableCell>{i + 1}</TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() => handleRowClick(row)}
                          component="th"
                          scope="row"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="text-capitalize"
                              variant="subtitle2"
                              noWrap
                            >
                              {subject ? subject && get_short_string(subject, 30):"-"}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {description ? description && get_short_string(description):"-"}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {department ? department && get_short_string(department?.title):"-"}
                        </TableCell>
                        <TableCell className="pl-0" align="left">
                          {user_id.first_name + " " + user_id.last_name ? user_id.first_name + " " + user_id.last_name:"-"}
                        </TableCell>

                        <TableCell align="center" className="pl-0">
                          <Label variant="filled" color="warning">
                            {comment_badge_count}
                          </Label>
                        </TableCell>

                        <TableCell className="pl-0" align="center">
                          {response_status === 0 && (
                            <Label
                              style={{ width: 70 }}
                              variant="ghost"
                              color="warning"
                            >
                              Waiting
                            </Label>
                          )}

                          {response_status === 1 && (
                            <Label
                              style={{ width: 70 }}
                              variant="ghost"
                              color="success"
                            >
                              Replied
                            </Label>
                          )}
                        </TableCell>

                        <TableCell className="pl-0" align="center">
                          {!CheckActivityTime(last_action_date) &&
                            ticket_status === 0 && (
                              <Label
                                style={{ width: 70 }}
                                variant="ghost"
                                color="info"
                              >
                                OPEN
                              </Label>
                            )}

                          {ticket_status === 1 && (
                            <Label
                              style={{ width: 70 }}
                              variant="ghost"
                              color="success"
                            >
                              CLOSED
                            </Label>
                          )}
                          {CheckActivityTime(last_action_date) &&
                            ticket_status === 0 && (
                              <Label variant="ghost" color="error">
                                Not Responding
                              </Label>
                            )}
                        </TableCell>
                        <TableCell className="pl-0" align="left">
                          {last_action_date ? moment(last_action_date).fromNow():"-"}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {createdAt ? moment(createdAt).format("LLL"):"-"}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          <Label
                            variant="ghost"
                            color={status ? "success" : "error"}
                          >
                            {status ? "Active" : "Inactive"}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Card>
      </div>
    </>
  );
}
