import {
  Box,
  Button,
  CircularProgress,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { _website_builder } from "src/DAL/Website/WebsiteBuilder";
import { _website_create } from "src/DAL/Website/WebsiteCreate";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import BuilderMenuModal from "../WebsiteBuilderDialog/BuilderDialog";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { useSnackbar } from "notistack";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { element } from "prop-types";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/CustomConfirmation";

export default function WebsiteBuilder() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [inputs, setInputs] = useState({
    title: "",
    menu_items: [],
  });
  const [menuInputs, setMenuInputs] = useState({
    title: "",
    background_color: "",
    image: null,
    button_items: [],
  });
  const [img, setImg] = useState("");
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log(file, "fileeeee");
    setImg(file.name);
  };
  const handleAddMenu = () => {
    setInputs((prev) => {
      return {
        ...prev,
        menu_items: [...prev.menu_items, { ...menuInputs }],
      };
    });
    setOpen(false);
    setMenuInputs({
      title: "",
      background_color: "",
      image: null,
      button_items: [],
    });
  };

  const GetWebBuilderDetail = async () => {
    setLoading(true);
    const BuilderData = await _landing_page_detail();
    if (BuilderData.code === 200) {
      setLoading(false);
      setInputs(BuilderData.data.website_builder);
    }

    console.log(BuilderData.data.website_builder, "sftycftasy");
  };

  useEffect(() => {
    GetWebBuilderDetail();
  }, []);

  const handleAddMenuButton = (i) => {
    setMenuInputs((prev) => {
      return {
        ...prev,
        button_items: [...prev.button_items, { text: "", link: "" }],
      };
    });
  };

  console.log(inputs.menu_items, "ctysfdtysad");
  const handleAddMenuItems = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tempMenu = [...inputs.menu_items];
    for (let i = 0; i < tempMenu.length; i++) {
      delete tempMenu[i]._id;
      for (let j = 0; j < tempMenu[i]?.button_items?.length; j++) {
        delete tempMenu[i]?.button_items[j]?._id;
      }

      if (typeof tempMenu[i]?.image == "string" && tempMenu[i]?.image !== "") {
        continue;
      }
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", tempMenu[i]?.image);

      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code === 200) {
        tempMenu[i].image = image_resp.file_name;
      } else {
        tempMenu[i].image = "";
      }

      delete tempMenu[i]?.local_image;
    }

    let postData = {
      title: inputs?.title,
      menu_items: [...tempMenu],
    };

    console.log(postData, "gggggggg");
    const resp = await _website_builder(postData);
    if (resp.code === 200) {
      enqueueSnackbar("data added successfully", { variant: "success" });
      GetWebBuilderDetail();
    } else {
      enqueueSnackbar("data not added successfully", { variant: "error" });
    }
  };

  const handleDelete = (row, i) => {
    setSelectedIndex(i);
    setOpenDelete(true);
  };

  const handleConfirmDelete = (i) => {
    setInputs((prev) => {
      return {
        ...prev,
        menu_items: prev.menu_items.filter((val, index) => index !== i),
      };
    });

    setOpenDelete(false);
  };

  const handleEdit = (row, i) => {
    console.log(i, "ftsyafd", row);
    setOpen(true);
    setMenuInputs(row);
    setSelectedIndex(i);
  };

  const handleEditData = (e) => {
    e.preventDefault();
    let newArr = inputs.menu_items;
    newArr[selectedIndex] = menuInputs;

    setInputs((prev) => {
      return { ...prev, menu_items: [...newArr] };
    });
    setOpen(false);
  };
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div style={{ width: "93%", margin: "auto", marginTop: "3rem" }}>
            <BuilderMenuModal
              open={open}
              setOpen={setOpen}
              imageBtn={handleImageUpload}
              menuInputs={menuInputs}
              setMenuInputs={setMenuInputs}
              addMenuButton={handleAddMenuButton}
              handleAddMenu={handleAddMenu}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              editData={handleEditData}
            />
            <div>
              <h3 className="text-center pb-3">Website Builder</h3>
              <div className="pb-3">
                <p>Title</p>
                <GeneralCkeditor
                  inputs={inputs}
                  setInputs={setInputs}
                  value={inputs?.title}
                  name="title"
                  onChange={handleChange}
                />

                <Button onClick={handleAddMenuItems} className="mt-3">
                  Add menu
                </Button>
              </div>

              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Menu Title</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Background Color</TableCell>
                      <TableCell>Button Text</TableCell>
                      <TableCell>Button Link</TableCell>
                      <TableCell>Button Background Color</TableCell>
                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inputs.menu_items.map((val, i) => {
                      console.log(val, "caucyauicyauickyaic");
                      return (
                        <>
                          <TableRow>
                            <TableCell> <div
                          dangerouslySetInnerHTML={{ __html: val?.title }}
                        /></TableCell>
                            <TableCell>
                              {val.local_image ? (
                                <>
                                  <img
                                    style={{ width: "4rem" }}
                                    src={val.local_image}
                                    alt=""
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    style={{ width: "4rem" }}
                                    src={s3baseUrl + "/" + val.image}
                                    alt=""
                                  />
                                </>
                              )}
                            </TableCell>
                            <TableCell>{val.background_color}</TableCell>
                            <TableCell>
                              {val.button_items.map((ele, i) => {
                                return (
                                  <li style={{ listStyleType: "none" }}>
                                    {ele.text}
                                  </li>
                                );
                              })}
                            </TableCell>

                            <TableCell>
                              {val.button_items.map((ele, i) => {
                                return (
                                  <li style={{ listStyleType: "none" }}>
                                    {ele.link}
                                  </li>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              {val.button_items.map((ele, i) => {
                                return (
                                  <li style={{ listStyleType: "none" }}>
                                    {ele.background_color}
                                  </li>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              <Icon
                                onClick={() => {
                                  setIsEdit(true);
                                  handleEdit(val, i);
                                }}
                                style={{ color: "green", cursor: "pointer" }}
                                icon={"fa-regular:edit"}
                              />
                            </TableCell>
                            <TableCell>
                              <Icon
                                onClick={() => handleDelete(val, i)}
                                style={{ color: "red", cursor: "pointer" }}
                                icon={"ic:baseline-delete"}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div className="text-end mt-3">
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </div>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are You sure you want to Delete ?"}
            handleAgree={() => handleConfirmDelete(selectedIndex)}
          />
        </>
      )}
    </>
  );
}
