import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { _ready_to_grow } from "src/DAL/Website/ReadyToGrow";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";

export default function ReadyToGrow() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    button: {
      text: "",
      link: "",
    },
  });

  const handleChange = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        title: e.target.value,
      };
    });
  };

  const GetReadyToGrowDetail = async () => {
    setLoading(true);
    const ReadyToGrowData = await _landing_page_detail();
    if (ReadyToGrowData.code === 200) {
      setLoading(false);
      setInputs(ReadyToGrowData?.data?.ready_to_grow);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      title: inputs?.title,
      button: {
        text: inputs?.button?.text,
        link: inputs?.button?.link,
      },
    };

    const resp = await _ready_to_grow(postData);
    if (resp.code === 200) {
      enqueueSnackbar("data added successfully", { variant: "success" });
      GetReadyToGrowDetail();
    } else {
      enqueueSnackbar("data not added successfully", { variant: "error" });
    }
    console.log(postData, "astydftyasfcty");
  };

  useEffect(() => {
    GetReadyToGrowDetail();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div className="mt-5" style={{ width: "94%", margin: "auto" }}>
            <h3 className="text-center">Ready To Grow</h3>
            <form className="mt-4">
              <div>
                <p>Title</p>
                <GeneralCkeditor
                  onChange={handleChange}
                  inputs={inputs}
                  setInputs={setInputs}
                  value={inputs?.title}
                  name="title"
                />
              </div>
              <div className="mt-3 col-12 d-flex gap-3">
                <TextField
                  label="Button Text"
                  required
                  style={{ width: "30%" }}
                  value={inputs?.button?.text}
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        button: {
                          ...prev.button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                  name="text"
                />
                <TextField
                  label="Button Link"
                  required
                  style={{ width: "30%" }}
                  value={inputs?.button?.link}
                  name="link"
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        button: {
                          ...prev.button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </div>
              <div className="text-end">
                <Button variant="contained" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}
