import { Box, Button, CircularProgress, Input, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _help_form } from "src/DAL/Website/HelpForm";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function HelpForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    background_image: "",
    form_background_image: "",
    form_side_image: "",
    button: {
      text: "",
      link: "",
    },
  });

  const GetHelpFormDetail = async () => {
    setLoading(true);
    const HelpFormData = await _landing_page_detail();
    if (HelpFormData.code === 200) {
      setLoading(false);
      setInputs(HelpFormData?.data?.help_form);
    }
  };

  useEffect(() => {
    GetHelpFormDetail();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let img_resp1 = "";
    let img_resp2 = "";
    let img_resp3 = "";
console.log(inputs,"asciuascyauicydauic")
    if (
      typeof inputs?.background_image === "string" &&
      typeof inputs?.form_background_image === "string" &&
      typeof inputs?.form_side_image === "string"
    ) {
      img_resp1 = inputs?.background_image;
      img_resp2 = inputs?.form_background_image;
      img_resp3 = inputs?.form_side_image;
    } else {
      if (typeof inputs?.background_image !== "string") {
        const formData = new FormData();
        formData.append("project_name", "upload_s3_files");
        formData.append("upload_file", inputs?.background_image);

        const image_resp = await _upload_file_on_s3(formData);

        if (image_resp.code === 200) {
          img_resp1 = image_resp.file_name;
        }
        delete inputs?.local_image1;
      } else {
        img_resp1 = inputs?.background_image;
      }

      if (typeof inputs?.form_background_image !== "string") {
        const formData2 = new FormData();
        formData2.append("project_name", "upload_s3_files");
        formData2.append("upload_file", inputs?.form_background_image);

        const image_resp2 = await _upload_file_on_s3(formData2);

        if (image_resp2.code === 200) {
          img_resp2 = image_resp2.file_name;
        } 
        delete inputs?.local_image2;
      } else {
        img_resp2 = inputs?.form_background_image;
      }

      if (typeof inputs?.form_side_image !== "string") {
        const formData3 = new FormData();
        formData3.append("project_name", "upload_s3_files");
        formData3.append("upload_file", inputs?.form_side_image);

        const image_resp3 = await _upload_file_on_s3(formData3);

        if (image_resp3.code === 200) {
          img_resp3 = image_resp3.file_name;
        }
        delete inputs?.local_image3;
      } else {
        img_resp3 = inputs?.form_side_image;
      }
    }

    const postData = {
      title: inputs?.title,
      background_image: img_resp1,
      form_background_image: img_resp2,
      form_side_image: img_resp3,
      button: {
        text: inputs?.button?.text,
        link: inputs?.button?.link,
      },
    };

    const result = await _help_form(postData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      GetHelpFormDetail();
    } else {
      enqueueSnackbar("data not added successfully", { variant: "error" });
    }
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        title: e.target.value,
      };
    });
  };
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <form>
            <div className="mt-3" style={{ width: "94%", margin: "auto" }}>
              <h3 className="text-center">Help Form</h3>
              <div className="mt-3">
                <p>Title</p>
                <GeneralCkeditor
                  inputs={inputs}
                  value={inputs?.title}
                  setInputs={setInputs}
                  name="title"
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex gap-3 mt-3">
                <TextField
                  label="Button Text"
                  required
                  style={{ width: "40%" }}
                  name="text"
                  value={inputs?.button?.text}
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        button: {
                          ...prev.button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
                <TextField
                  label="Button Link"
                  required
                  value={inputs?.button?.link}
                  style={{ width: "40%" }}
                  name="link"
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        button: {
                          ...prev.button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </div>

              <div className="d-flex justify-content-around mt-3">
                <div>
                  <img
                    style={{ width: "15rem", height: "8rem" }}
                    src={
                      inputs.local_image1
                        ? inputs?.local_image1
                        : s3baseUrl + "/" + inputs?.background_image
                    }
                    alt=""
                  />
                  <label htmlFor="icon-button-file">
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      name="background_image"
                      onChange={(e) =>
                        setInputs((prev) => {
                          return {
                            ...prev,
                            local_image1: URL.createObjectURL(
                              e.target.files[0]
                            ),
                            background_image: e.target.files[0],
                          };
                        })
                      }
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button mt-3"
                    >
                      Background Image
                    </Button>
                  </label>
                </div>
                <div>
                  <label htmlFor="icon-button-file-1">
                    <img
                      style={{ width: "15rem", height: "8rem" }}
                      src={
                        inputs.local_image2
                          ? inputs?.local_image2
                          : s3baseUrl + "/" + inputs?.form_background_image
                      }
                      alt=""
                    />
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file-1"
                      type="file"
                      name="form_background_image"
                      onChange={(e) =>
                        setInputs((prev) => {
                          return {
                            ...prev,
                            local_image2: URL.createObjectURL(
                              e.target.files[0]
                            ),
                            form_background_image: e.target.files[0],
                          };
                        })
                      }
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button mt-3"
                    >
                      Form Background Image
                    </Button>
                  </label>
                </div>
                <div>
                  <label htmlFor="icon-button-file-2">
                    <img
                      style={{ width: "15rem", height: "8rem" }}
                      src={
                        inputs.local_image3
                          ? inputs?.local_image3
                          : s3baseUrl + "/" + inputs?.form_side_image
                      }
                      alt=""
                    />
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file-2"
                      type="file"
                      name="form_side_image"
                      onChange={(e) =>
                        setInputs((prev) => {
                          return {
                            ...prev,
                            local_image3: URL.createObjectURL(
                              e.target.files[0]
                            ),
                            form_side_image: e.target.files[0],
                          };
                        })
                      }
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button mt-3"
                    >
                      Form Side Image
                    </Button>
                  </label>
                </div>
              </div>
              <div className="text-end mt-3">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}
