import { Icon } from "@iconify/react";
import { useRef, useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, MenuItem } from "@mui/material";

// components
import MenuPopover from "./MenuPopover";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CustomPopover(props) {
  const anchorRef = useRef(null);
  const { menu, data, setVersions, projects } = props;

 
  
  
  
  // setCheckVersionsById(data._id);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    if(projects)setVersions(data.version_count);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className="align-items-center d-flex justify-content-center remove-border"
    >
      <MoreVertIcon
        style={{ cursor: "pointer", fontSize: 20, color: "#637381" }}
        className="pointer custom-popover-icon"
        ref={anchorRef}
        onClick={handleOpen}
      />
      <MenuPopover
        className="custom-popover"
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 200, marginLeft: 1.8 }}
      >
        {menu &&
          menu.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                setOpen(false);
                option.handleClick(data);
              }}
              disabled={option.hide}
            >
              {option.icon && (
                <Icon fontSize="18" className="" icon={option.icon} />
              )}
              <span className="ps-2">{option.label}</span>
            </MenuItem>
          ))}
      </MenuPopover>
    </div>
  );
}
