// import { Button, Input, Table, TextField } from "@mui/material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Table,
  TextField,
  Input,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import FooterModal from "./FooterDialog";
import { Icon } from "@iconify/react";
import CustomConfirmation from "src/components/CustomConfirmation";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { _add_footer } from "src/DAL/Website/Footer";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";

export default function Footer() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    logo_link: "",
    facebook_link: "",
    linkedin_link: "",
    logo: "",
    background_image: "",
  });

  console.log(inputs,"dtaysfdtsaydf")
  const [menuInputsObj, setMenuInputsObj] = useState({});
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const GetFooterDetail = async () => {
    setLoading(true);
    const FooterData = await _landing_page_detail();
    if (FooterData.code === 200) {
      setLoading(false);
      setMenuInputsObj(FooterData?.data?.footer);
      setInputs(FooterData?.data?.footer);
      console.log(FooterData?.data?.footer, "dytafdtysdf");
    }
  };

  const handleAddMenuData = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let img_resp1 = "";
    let img_resp2 = "";

    let newArray = [...menuInputsObj.menu_items];

    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i]._id) {
        delete newArray[i]._id;
      }
    }
    if (typeof inputs?.logo === "string" && typeof inputs?.background_image === "string") {
      img_resp1 = inputs?.logo;
      img_resp2 = inputs?.background_image;
    } else {
      if(typeof inputs?.logo !== "string"){
        const formData = new FormData();
        formData.append("project_name", "upload_s3_files");
        formData.append("upload_file", inputs?.logo);
  
        const image_resp = await _upload_file_on_s3(formData);
        if (image_resp.code === 200) {
          img_resp1 = image_resp?.file_name;
        }
        delete inputs?.local_image;
      }else{
        img_resp1 = inputs?.logo
      }

      if(typeof inputs?.background_image !== "string"){
        const formData = new FormData();
        formData.append("project_name", "upload_s3_files");
        formData.append("upload_file", inputs?.background_image);
  
        const image_resp = await _upload_file_on_s3(formData);
        if (image_resp.code === 200) {
          img_resp2 = image_resp?.file_name;
        }
        delete inputs?.local_image2;
      }else{
        img_resp2 = inputs?.background_image
      }
     
    }

    const postData = {
      logo: img_resp1,
      logo_link: inputs?.logo_link,
      background_image: img_resp2,
      menu_items: [...newArray],
      facebook_link: inputs?.facebook_link,
      linkedin_link: inputs.linkedin_link,
    };
console.log(postData,"dadtysfdyusgd")
    const result = await _add_footer(postData);
    if (result.code === 200) {
      enqueueSnackbar(`data added successfully`, {
        variant: "success",
      });
      GetFooterDetail();
    } else {
      enqueueSnackbar(`data not added `, {
        variant: "error",
      });
    }
  };

  const handleEdit = (row, i) => {
    console.log(row, "ftsayf");
    setData(row);
    setSelectedIndex(i);
    setOpen(true);
  };

  const handleDelete = (row, i) => {
    setSelectedIndex(i);
    setOpenDelete(true);

    // headerDetailList();
  };

  const handleConfirmDelete = (i) => {
    let obj = menuInputsObj;
    obj.menu_items.splice(i, 1);
    setMenuInputsObj({ ...obj });
    setOpenDelete(false);
  };

  useEffect(() => {
    GetFooterDetail();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
        <form >
          <div style={{ width: "94%", margin: "auto" }}>
            <FooterModal
              open={open}
              setOpen={setOpen}
              menuInputsObj={menuInputsObj}
              setMenuInputsObj={setMenuInputsObj}
              data={data}
              setData={setData}
              selectedIndex={selectedIndex}
            />
            <h3 className="text-center">Footer</h3>
            <div className="d-flex gap-3 mt-5">
              <TextField
                label="logo link"
                name="logo_link"
                required
                value={inputs?.logo_link}
                style={{ width: "33%" }}
                onChange={handleChange}
              />
              <TextField
                label="Facebook Link"
                value={inputs?.facebook_link}
                name="facebook_link"
                required
                style={{ width: "33%" }}
                onChange={handleChange}
              />
              <TextField
                label="LinkedIn link"
                required
                value={inputs?.linkedin_link}
                name="linkedin_link"
                style={{ width: "33%" }}
                onChange={handleChange}
              />
            </div>

            <div className="d-flex justify-content-center gap-5 mt-5">
              <div className="text-center">
                <img
                  style={{ width: "15rem", height: "8rem" }}
                  src={
                    inputs?.local_image
                      ? inputs?.local_image
                      : s3baseUrl + "/" + inputs?.logo
                  }
                  alt=""
                />
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="logo"
                    onChange={(e) =>
                      setInputs((prev) => {
                        return {
                          ...prev,
                          local_image: URL.createObjectURL(e.target.files[0]),
                          logo: e.target.files[0],
                        };
                      })
                    }
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button mt-3 "
                  >
                    Choose Logo
                  </Button>
                </label>
              </div>
              <div className="text-center">
                <img
                  style={{ width: "15rem", height: "8rem" }}
                  src={
                    inputs?.local_image2
                      ? inputs?.local_image2
                      : s3baseUrl + "/" + inputs?.background_image
                  }
                  alt=""
                />
                <label htmlFor="icon-button-file-1">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file-1"
                    type="file"
                    name="background_image"
                    onChange={(e) =>
                      setInputs((prev) => {
                        return {
                          ...prev,
                          local_image2: URL.createObjectURL(e.target.files[0]),
                          background_image: e.target.files[0],
                        };
                      })
                    }
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button mt-3 "
                  >
                    Choose Background Image
                    {/* {inputs.logo != "" ? "Change Logo" : "Upload Logo"} */}
                  </Button>
                </label>
              </div>
            </div>
            <div className="mt-4">
              <Button onClick={handleAddMenuData}>Add Menu Data</Button>
            </div>

            <div>
              <Table className="mt-5">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menuInputsObj?.menu_items?.map((val, i) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>{val.title}</TableCell>
                          <TableCell>{val.link}</TableCell>
                          <TableCell>
                            <Icon
                              onClick={() => handleEdit(val, i)}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Icon
                              onClick={() => handleDelete(val, i)}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="text-end">
              <Button variant="contained" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </div>

            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to Delete ?"}
              handleAgree={() => handleConfirmDelete(selectedIndex)}
            />
          </div>
          </form>
        </>
      )}
    </>
  );
}
