import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useMemo, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../components/_dashboard/user";
//
import USERLIST from "../../../_mocks_/user";
import {
  _customer_list,
  _delete_customer,
  _get_customer_token_for_login,
  _details_against_userID,
} from "src/DAL/customer/customer";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import UpdateCustomerPassword from "../Update-Customer-Password/UpdateCustomerPassword";
import AddCustomer from "../Add-Customer/AddCustomer";
import { clientBaseUri, s3baseUrl } from "src/config/config";
import moment from "moment";
import ProjectList from "../Project-List/ProjectList";
import UpdateCustomerProfileName from "../Update-Customer-Profile-Name/UpdateCustomerProfileName";

import debounce from "../../../utils/debounce";
// import { debounce } from "lodash";

import DetailModal from "../../Detail-Section/Detail-Section";
import { useParams } from "react-router-dom";
import { formatSizeUnits } from "src/utils/utils";
import GalleryModal from "../../GalleryDialog/Gallery.jsx";
import ImagePopover from "src/pages/Image-Popover/Image-Popover";
import { _plan_list } from "src/DAL/plans/plan";
import { CSVLink } from "react-csv";
import { useAppContext } from "src/Hooks/AppContext";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "", label: "", alignRight: false },
  { id: "first_name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "type", label: "User Type", alignRight: false },
  { id: "plan_name", label: "Plan", alignRight: false },
  { id: "contact_number", label: "Contact Number", alignRight: false },
  { id: "funel_count", label: "Funel Count", alignRight: false },
  { id: "page_count", label: "Pages Count", alignRight: false },
  // { id: "business_country", label: "Country", alignRight: false },
  { id: "business_type", label: "Business Type", alignRight: false },
  { id: "business_name", label: "Business Name", alignRight: false },
  { id: "project_count", label: "Project Count", alignRight: false },
  // { id: "post_code", label: "Postal Code", alignRight: false },
  { id: "createdAt", label: "Registration Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        // _user.business_country.toLowerCase().indexOf(query.toLowerCase()) !==
        //   -1 ||
        _user.post_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomerListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { user, getObjOfPrivileges, subAdminRole } = useAppContext();
  const [rolePrivilegesObj, setRolePrivilegesObj] = useState({});
  
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  

  // const [debouncedFilterName, setDebouncedFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([1, 2]);
  const [open, setOpen] = useState(false);

  const [openGalleryModel, setOpenGalleryModel] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenAdd, setIsDrawerOpenAdd] = useState(false);
  const [isDrawerOpenProfile, setIsDrawerOpenProfile] = useState(false);
  const [detail, setDetail] = useState();
  const [planList, setPlanList] = useState();
  const [selectedPlan, setSelectedPlan] = useState("all");

  const [selectedRow, setSelectedRow] = useState({});
  console.log(selectedRow, "selectrowwwwwwww");
  const [OpenProjectList, setOpenProjectList] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState();
  const [loading, setLoading] = useState(false);

  const [selectImage, setSelectImage] = useState([]);
  const [openImagePopover, setOpenImagePopover] = useState(false);

  const [selectImageRow, setSelectImageRow] = useState([]);

  const handleFilterByName = (event) => {
    const value = event.target.value;
    setFilterName(value);
  };

  const handleOpenChangePassword = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseChangePassword = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenChangeProfile = () => {
    setIsDrawerOpenProfile(true);
  };

  const handleCloseChangeProfile = () => {
    setIsDrawerOpenProfile(false);
  };

  const handleOpenAdd = () => {
    setIsDrawerOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setIsDrawerOpenAdd(false);
  };
  const openDetailDialog = () => {
    setOpen(true);
  };
  const closeDetailDialog = () => {
    setOpen(false);
    setSelectedRow({});
  };
  const showUserDetail = async (row) => {
    console.log(row, "showdetailrow");
    setSelectedRow(row.row);
    openDetailDialog();
  };

  const handleDelete = async () => {
    console.log(selectedRow, "selectedRow");
    const delete_resp = await _delete_customer(selectedRow._id);
    if (delete_resp.code === 200) {
      setOpenDelete(false);

      setData((prev) => {
        return prev.filter((item) => item._id !== selectedRow._id);
      });

      enqueueSnackbar("Customer Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const isUserNotFound = data.length === 0;

  const get_customer_list = async (query, list = []) => {
    setLoading(true);
    const postData = {
      search: query,
      list_type: list,
      plan_id: selectedPlan == "all" || !compareArrays() ? "" : selectedPlan,
    };
    const customer_list_resp = await _customer_list(
      page,
      rowsPerPage,
      postData
    );

    // console.log(customer_list_resp, "customerlistresponse");
    if (customer_list_resp.code === 200) {
      setTotalPages(customer_list_resp.pages);
      const customers = customer_list_resp.customer.map((customer, index) => {
        return {
          ...customer,
          email: customer.user_id.email,
          // email is commented bcz after handling error in /customer/project details/pages details
          count: index + 1 + rowsPerPage * page,
          plan_name: customer.plan_id?.product_name,
        };
      });
      setLoading(false);
      setData(customers);

      // console.log(customers, "customers ");
      setTotalCount(customer_list_resp.count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(customer_list_resp.message, { variant: "error" });
    }
  };

  const handleUpdatePasswordClick = (row) => {
    setSelectedRow(row.row);
    handleOpenChangePassword();
  };

  const handleUpdateProfile = (data) => {
    handleOpenChangeProfile();
    // // setIsDrawerOpenProfile(true);
    setSelectedIndex(data.index);
    setSelectedRow(data.row);
  };

  const handleShowProjectList = (row) => {
    setSelectedRow(row.row);
    setOpenProjectList(true);
  };

  const handleShowBackups = (row) => {
    // console.log(row.row.user_id?._id, "rowwwwwwwwwwwww");
    setSelectedRow(row.row);
    navigate(`/backup-publish-projects/${row.row.user_id?._id}`);
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row.row);
    setOpenDelete(true);
  };

  const handleViewCustomersGallery = (row) => {
    console.log(row, "customerssss");
    setSelectImageRow(row.row);
    setOpenGalleryModel(true);
  };

  const handleLoginAsCustomer = async (row) => {
    const postData = {
      user_id: row.row.user_id._id,
    };
    console.log(row, "row_customer");
    const token_resp = await _get_customer_token_for_login(postData);
    if (token_resp.code == 200) {
      const URL = `${clientBaseUri}/controller/login/${token_resp.token}`;
      window.open(URL, "_blank");
    } else {
      enqueueSnackbar(token_resp.message, { variant: "error" });
    }
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Update Password",
      icon: "akar-icons:edit",
      handleClick: handleUpdatePasswordClick,
    },
    {
      label: "Edit Customer",
      icon: "akar-icons:edit",
      handleClick: handleUpdateProfile,
    },
    {
      label: "Customers Gallery",
      icon: "solar:gallery-broken",
      handleClick: handleViewCustomersGallery,
    },

    {
      label: "Project List",
      icon: "bx:list-ol",
      handleClick: handleShowProjectList,
    },

    {
      label: "Backups",
      icon: "fluent:cloud-backup-48-regular",
      handleClick: handleShowBackups,
    },
    {
      label: "Detail",
      icon: "mdi:card-account-details-outline",
      handleClick: showUserDetail,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },

    {
      label: "Login As Customer",
      icon: "carbon:login",
      handleClick: handleLoginAsCustomer,
    },
  ]);

  // const handleRefresh = () => {
  //   get_customer_list();
  // };

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          className={selectedRow.type == [3] ? "label" : ""}
          variant="ghost"
          color={
            selectedRow.type == [1]
              ? "success"
              : selectedRow.type == [2]
              ? "success"
              : "error"
          }
        >
          {Active}
        </Label>
      </>
    );
  };

  const StatusColor = (val) => {
    return (
      <div>
        <Label color={selectedRow.status == 1 ? "success" : "error"}>
          {val}
        </Label>
      </div>
    );
  };

  const PayStatusColor = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={selectedRow.payment_status ? "success" : "error"}
        >
          {val}
        </Label>
      </div>
    );
  };

  const bgColor = (val) => {
    return (
      <div>
        <Label className="bg">{val}</Label>
      </div>
    );
  };

  const VerStatCol = (val) => {
    return (
      <>
        <Label
          color={selectedRow.verification_status == 1 ? "success" : "error"}
        >
          {val}
        </Label>
      </>
    );
  };

  const handleImagePopover = (row) => {
    setSelectImage(row);
    setOpenImagePopover(true);
  };

  const profileImg = (row) => {
    return (
      <img
        onClick={() => handleImagePopover(row)}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        src={`${s3baseUrl}/${row.profile_image}`}
        alt="No Img"
      />
    );
  };

  useEffect(() => {
    if (selectedRow) {
      setDetail([
        {
          name: "First Name",
          value: selectedRow.first_name ? (
            selectedRow.first_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Last Name",
          value: selectedRow.last_name ? selectedRow.last_name : <span>-</span>,
        },
        {
          name: "Contact",
          value: selectedRow.contact_number ? (
            selectedRow.contact_number
          ) : (
            <span>-</span>
          ),
        },

        {
          name: "Email",
          value: selectedRow.email ? selectedRow.email : <span>-</span>,
        },
        {
          name: "User Type",
          value: IsActiveFunc(
            selectedRow.type == [1]
              ? "Inovate"
              : selectedRow.type == [2]
              ? "Inovate"
              : selectedRow.type == [3]
              ? "Vission"
              : ""
          ),
        },
        {
          name: "Business Name",
          value: selectedRow.business_name ? (
            selectedRow.business_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Business Type",
          value:
            selectedRow?.business_type?.length > 0 ? (
              selectedRow.business_type + "  " + "  "
            ) : (
              <span>-</span>
            ),
        },
        {
          name: "Profile Image",
          value: selectedRow.profile_image ? (
            profileImg(selectedRow)
          ) : (
            <span className="fw-bold">No Img</span>
          ),
        },

        {
          name: "Post Code",
          value: selectedRow.post_code ? selectedRow.post_code : <span>-</span>,
        },

        {
          name: "Count",
          value: selectedRow.count ? (
            bgColor(selectedRow.count)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Payment Status",
          value: selectedRow.payment_status ? (
            PayStatusColor(
              selectedRow.payment_status == false ? "InActive" : "Active"
            )
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Plan",
          value: selectedRow.plan_id ? (
            selectedRow.plan_id.product_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Plan Amount",
          value: selectedRow.plan_id ? (
            selectedRow.plan_id.amount + " " + selectedRow.plan_id.currency
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Plan Interval",
          value: selectedRow.plan_id ? (
            selectedRow.plan_id.interval_time
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Funel Count",
          value: selectedRow.funel_count ? (
            bgColor(selectedRow.funel_count)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Pages Count",
          value: selectedRow.page_count ? (
            bgColor(selectedRow.page_count)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Project Count",
          value: selectedRow.project_count ? (
            bgColor(selectedRow.project_count)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Stripe Customer Id",
          value: selectedRow.stripe_customer_id ? (
            selectedRow.stripe_customer_id
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Subscription Status",
          value: bgColor(selectedRow.subscription_status),
        },
        {
          name: "Status",
          value: StatusColor(selectedRow.status == 1 ? "Active" : "InActive"),
        },
        {
          name: "Verification Status",
          value: VerStatCol(
            selectedRow.verification_status == 0 ? "False" : "True"
          ),
        },

        {
          name: "Total Asserts File Size",
          value:
            (selectedRow.total_asserts_file_size / 1073741824).toFixed(0) +
            "    GB " +
            "  " +
            "    -    " +
            (selectedRow.total_asserts_file_size / 1048576).toFixed(0) +
            "  MB" +
            "  " +
            "    -    " +
            (selectedRow.total_asserts_file_size / 1024).toFixed(0) +
            "   KB",
        },
        {
          name: "Total Asserts File Count",
          value: bgColor(selectedRow.total_asserts_file_count),
        },
        {
          name: "Created At",
          value: selectedRow.createdAt ? (
            moment(selectedRow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectedRow.updatedAt ? (
            moment(selectedRow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
    }
  }, [selectedRow]);

  const debouncedFetchData = (filterName, filter) => {
    debounce(500, () => get_customer_list(filterName, filter));
  };

  const handleFilterCustomerByPlan = (e) => {
    setSelectedPlan(e.target.value);
  };

  useEffect(() => {
    debouncedFetchData(filterName, filter);
  }, [page, rowsPerPage, filter, filterName, selectedPlan]);

  const fetchPlans = async () => {
    const resp = await _plan_list();
    if (resp.code == 200) {
      setPlanList([...resp.data]);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);
  useEffect(() => {
    if (user && user.admin_type === "sub_admin" && subAdminRole.roles) {
      let obj = getObjOfPrivileges("Customers");
      setRolePrivilegesObj(obj);
      console.log(obj, "condolelelellelele");
      let _array = menuOptions;
      if (!obj.edit) {
        _array = [
          ..._array.filter(
            (option) =>
              option.label != "Edit Customer" &&
              option.label != "Update Password"
          ),
        ];
      }
      if (!obj.delete) {
        _array = [..._array.filter((option) => option.label != "Delete")];
      }

      setMenuOptions([..._array]);
    }
  }, [subAdminRole]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, filterName]);

  const compareArrays = () => {
    const isEqual =
      filter.length > 0 &&
      filter.every((value, index) => value === [1, 2][index]);
    return isEqual;
  };

  const getFormattedData = () => {
    const row = [
      ["Name", "Email", "Plan", "Contact Number", "Registration Date"],
    ];
    data.map((d) => {
      const csv_row = [
        d.first_name + " " + d.last_name,
        d.email,
        d.plan_name,
        d.contact_number,
        moment(d.createdAt).format("LLL"),
      ];

      row.push(csv_row);
    });

    return row;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DetailModal open={open} setOpen={setOpen} detail={detail} />

      <GalleryModal
        open={openGalleryModel}
        setOpen={setOpenGalleryModel}
        ImageCustomer={selectImageRow}
      />

      <ImagePopover
        open={openImagePopover}
        setOpen={setOpenImagePopover}
        imgData={selectImage}
      />

      {selectedRow && (
        <ProjectList
          open={OpenProjectList}
          setOpen={setOpenProjectList}
          data={selectedRow}
          index={selectedIndex}
        />
      )}

      <div className="container ">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
          {(user.admin_type === "super_admin" || rolePrivilegesObj?.add) && (
            <Button
              onClick={handleOpenAdd}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Add Customer
            </Button>
          )}
        </Stack>

        <Card>
          <div className="left-section d-flex align-items-center justify-content-between">
            <div className="buttons">
              <Stack direction="row" spacing={2} margin={3}>
                <Button
                  variant={filter.length == 2 ? "contained" : "outlined"}
                  onClick={() => {
                    if (totalCount == rowsPerPage) {
                      setRowsPerPage(25);
                    }
                    setFilter([1, 2]);
                  }}
                >
                  Inovate
                </Button>
                <Button
                  variant={filter.length == 1 ? "contained" : "outlined"}
                  onClick={() => {
                    if (totalCount == rowsPerPage) {
                      setRowsPerPage(25);
                    }
                    setFilter([3]);
                  }}
                >
                  Vission
                </Button>
                <Button
                  variant={filter.length == 0 ? "contained" : "outlined"}
                  onClick={() => {
                    if (totalCount == rowsPerPage) {
                      setRowsPerPage(25);
                    }
                    setFilter([]);
                  }}
                >
                  All
                </Button>
              </Stack>
            </div>
            <div className="d-flex align-items-center">
              {compareArrays() && (
                <>
                  <div className="me-3">
                    <Tooltip title="Download CSV File">
                      <div>
                        <CSVLink data={getFormattedData()}>
                          <Icon
                            fontSize="24"
                            icon="bi:filetype-csv"
                            style={{
                              cursor: "pointer",
                              color: "GrayText",
                            }}
                          />
                        </CSVLink>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="d-flex align-items-center">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        {"Filter (plan base)"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedPlan}
                        label="Filter (plan base)"
                        onChange={handleFilterCustomerByPlan}
                        style={{ width: 200 }}
                      >
                        <MenuItem index value={"all"}>
                          All
                        </MenuItem>
                        {planList.map((plan) => (
                          <MenuItem index value={plan._id}>
                            {plan.product_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </div>
          </div>

          <>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {data.map((row, index) => {
                      const {
                        _id,
                        first_name,
                        last_name,
                        contact_number,
                        funel_count,
                        page_count,
                        post_code,
                        status,
                        plan_name,
                        // business_country,
                        email,
                        type,
                        count,
                        createdAt,
                        business_type,
                        business_name,
                        project_count,
                      } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>{count}</TableCell>
                          <TableCell align="right">
                            <CustomPopover
                              data={{ row, index }}
                              menu={
                                type == 3
                                  ? menuOptions.filter(
                                      (option) =>
                                        option.label != "Login As Customer" &&
                                        option.label != "Edit Customer" &&
                                        option.label != "Update Password"
                                    )
                                  : menuOptions
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography
                                className="text-capitalize "
                                style={{ cursor: "pointer" }}
                                variant="subtitle2"
                                noWrap
                                onClick={() => showUserDetail({ row, index })}
                              >
                                {first_name + " " + last_name
                                  ? first_name + " " + last_name
                                  : "-"}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            {email ? email : "-"}
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              className={type == [3] ? "label" : ""}
                              color="success"
                            >
                              {type == [1]
                                ? "Inovate"
                                : type == [2]
                                ? "Inovate"
                                : type == [3]
                                ? "Vission"
                                : ""}
                            </Label>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <span
                              style={{ width: "max-content", display: "block" }}
                            >
                              {plan_name ? plan_name : "-"}
                            </span>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            {contact_number ? contact_number : "-"}
                          </TableCell>
                          <TableCell width={200} className="pl-0" align="left">
                            <Label variant="ghost" color="success">
                              {funel_count}
                            </Label>
                          </TableCell>

                          <TableCell width={200} className="pl-0" align="left">
                            <Label variant="ghost" color="success">
                              {page_count}
                            </Label>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            {business_type.length > 0 ? (
                              business_type.map((item) => (
                                <Typography key={item.value}>{item}</Typography>
                              ))
                            ) : (
                              <span>-</span>
                            )}
                          </TableCell>
                          <TableCell
                            className="pl-0"
                            align="left"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {business_name ? business_name : "-"}
                          </TableCell>

                          <TableCell width={200} className="pl-0" align="left">
                            <Label variant="ghost" color="success">
                              {project_count}
                            </Label>
                          </TableCell>

                          <TableCell
                            className="pl-0"
                            align="left"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {createdAt ? moment(createdAt).format("LLL") : "-"}
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {status ? "Active" : "Inactive"}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this customer?"}
              handleAgree={handleDelete}
            />
          </>

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[25, 50, 100, totalCount]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
      <UpdateCustomerPassword
        isOpenDrawer={isDrawerOpen}
        onOpenDrawer={handleOpenChangePassword}
        onCloseDrawer={handleCloseChangePassword}
        selectedRow={selectedRow}
      />

      <AddCustomer
        isOpenDrawer={isDrawerOpenAdd}
        onOpenDrawer={handleOpenAdd}
        onCloseDrawer={handleCloseAdd}
        handleRefresh={() => {}}
      />

      <UpdateCustomerProfileName
        isOpenDrawer={isDrawerOpenProfile}
        onOpenDrawer={handleOpenChangeProfile}
        onCloseDrawer={handleCloseChangeProfile}
        selectedRow={selectedRow}
        selectedIndex={selectedIndex}
        setSelectedRow={setSelectedRow}
        get_customer_list={get_customer_list}
        setData={setData}
      />
    </>
  );
}
