import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import debounce from "../../../utils/debounce";
import { saveAs } from "file-saver";
import { Typeahead } from "react-bootstrap-typeahead";

import { _project_subscriber_listing } from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";

import moment from "moment";
import { useSnackbar } from "notistack";
import {
  _select_members,
  _transaction_list,
  _transaction_list_members,
} from "src/DAL/Transactions/Transaction";
import Label from "../../../components/Label";
import DetailModal from "../../Detail-Section/Detail-Section";
import CustomPopover from "src/components/CustomPopover";
import { Icon } from "@iconify/react";
import { useAppContext } from "src/Hooks/AppContext";

export default function TransactionList() {
  const { id } = useParams();
  const [dataList, setDataList] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const { user, getObjOfPrivileges, subAdminRole } = useAppContext();
  const [rolePrivilegesObj, setRolePrivilegesObj] = useState({});

  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [loading, setLoading] = useState(false); // Loading state
  const [searchTerm, setSearchTerm] = useState("");

  const [filterTransaction, setFilterTransaction] = useState([1, 2]);
  const [selectType, setSelectType] = useState("innovate");
  // console.log(filterTransaction, "rrrrrrrr");

  const [selectTransactionRow, setSelectTransactionRow] = useState([]);
  const [openTransactionDetail, setOpenTransactionDetail] = useState(false);
  console.log(selectTransactionRow, "staterowdetail");

  const [members, setMembers] = useState([]);
  const [input, setInput] = useState([]);

  const [transactionDetail, setTransactionDetail] = useState();

  // states for the filter

  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");

  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(25); // Number of rows per page
  // console.log(rowsPerPage, "nhghjgfhfjh");
  const [isFiltering, setIsFiltering] = useState(false);
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    status: "",
    search: [],
  });
  useEffect(() => {
    console.log(filters);
  }, [filters]);
  const handleChangeFilters = (e) => {
    setIsFiltering(true);
    console.log(e.target.value, " dsfdafaf", e.target.name);
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const memberList = async () => {
    const postData = {
      customer_type:
        selectType == "innovate"
          ? 1
          : selectType == "vission"
          ? 3
          : selectType == "all" && 0,
    };
    const memberlist_response = await _transaction_list_members(postData);
    if (memberlist_response.code == 200) {
      setMembers(
        memberlist_response.data.customer.map((user) => {
          return user.first_name + " " + user.last_name;
        })
      );
    }
    console.log(memberlist_response.data.customer, "memberlistresponse");
  };
  // console.log(members, "dgdthfhfhfh");
  useEffect(() => {
    memberList();
  }, [selectType]);

  const transaction_List = async (list = [], filter = false) => {
    setLoading(true);
    setIsFiltering(filter);
    const postData = filter
      ? {
          ...filters,
          start_date: filters.start_date
            ? moment(filters.start_date).format("MM-DD-YYYY")
            : "01-01-2022", // Default start date if no date is selected
          end_date: filters.end_date
            ? moment(filters.end_date).format("MM-DD-YYYY")
            : "",
          list_type: list,
          search: filters.search.length > 0 ? filters.search[0] : "",
        }
      : {
          start_date: "01-01-2022", // Default start date if no date is selected
          end_date: "",
          status: "",
          search: "",
          list_type: [1, 2],
        };

    if (!filters.start_date) {
      postData.start_date = "01-01-2022"; // Update start date if no date is selected
    }

    // Add pagination parameters

    const transaction_list_response = await _transaction_list(
      postData,
      page,
      rowsPerPage
    );
    console.log(transaction_list_response, "asckjsacaslckjasklcjs");
    if (transaction_list_response.code == 200) {
      setDataList(transaction_list_response.data);
    } else {
      enqueueSnackbar(transaction_list_response.message, { variant: "error" });
    }
    setLoading(false);

    // console.log(transaction_list_response, "transaction");

    // console.log(
    //   transaction_list_response.data.transaction,
    //   "transactionlistingggggg"
    // );

    // console.log(dataList, "datattatat");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  // console.log(s3baseUrl, "cjdjcdjncjdjjnc");

  // const downloadFile = (url, e) => {
  //   e.preventDefault();
  //   try {
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("onclick", (evt) => evt.preventDefault());

  //     link.download = url.split("/").pop();
  //     link.click();
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleFilterClick = () => {
    setPage(0);
    if (filters.start_date && !filters.end_date) {
      enqueueSnackbar("Enter End Date ", { variant: "error" });
      // setIsFiltering(false);
    } else if (!filters.start_date && filters.end_date) {
      enqueueSnackbar("Enter Start Date ", { variant: "error" });
    } else if (filters.start_date > filters.end_date) {
      enqueueSnackbar("Start Date is Greater ", { variant: "error" });
    } else {
      transaction_List(filterTransaction, true);
    }
  };

  const handleClearFilterClick = () => {
    setFilters({
      start_date: "",
      end_date: "",
      status: "",
      search: [],
    });
    setSelectType("innovate");

    transaction_List(filterTransaction, false);
  };

  // useEffect(() => {
  //   setPage(0);
  // }, [rowsPerPage, isFiltering]);

  useEffect(() => {
    transaction_List(filterTransaction, isFiltering);
  }, [page, rowsPerPage]);

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleMenuClose,
    },
  ]);

  const bgColor = (val) => {
    return (
      <div>
        <Label className="bg">{val}</Label>
      </div>
    );
  };

  const StatusColor = (val) => {
    return (
      <div>
        <Label
          color={
            selectTransactionRow.plan_id?.status == 0 ? "error" : "success"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          className={selectTransactionRow?.user_obj?.type == [3] ? "label" : ""}
          variant="ghost"
          color={
            selectTransactionRow?.user_obj?.type == [1]
              ? "success"
              : selectTransactionRow?.user_obj?.type == [1]
              ? "success"
              : "error"
          }
        >
          {Active}
        </Label>
      </>
    );
  };

  useEffect(() => {
    if (selectTransactionRow) {
      setTransactionDetail([
        {
          name: "Customer Name",
          value: selectTransactionRow.customer_name ? (
            selectTransactionRow.customer_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Customer Email",
          value: selectTransactionRow?.user_obj?.email ? (
            selectTransactionRow?.user_obj?.email
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Customer Type",
          value: IsActiveFunc(
            selectTransactionRow?.user_obj?.type == [1]
              ? "Inovate"
              : selectTransactionRow?.user_obj?.type == [2]
              ? "Inovate"
              : selectTransactionRow?.user_obj?.type == [3]
              ? "Vission"
              : ""
          ),
        },
        {
          name: "Card",
          value: selectTransactionRow.card ? (
            selectTransactionRow.card
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Card Brand",
          value: selectTransactionRow.card_brand ? (
            selectTransactionRow.card_brand
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Amount",
          value: selectTransactionRow.amount ? (
            selectTransactionRow.amount
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Currency",
          value: selectTransactionRow.currency ? (
            selectTransactionRow.currency
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Transaction Date",
          value: selectTransactionRow.transaction_date ? (
            selectTransactionRow.transaction_date
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Transaction Status",
          value: selectTransactionRow.transaction_status ? (
            selectTransactionRow.transaction_status
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "User Id",
          value: selectTransactionRow.user_id ? (
            selectTransactionRow.user_id
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Status",
          value: StatusColor(
            selectTransactionRow?.plan_id?.status == 0 ? "false" : "true"
          ),
        },

        {
          name: "Time Interval",
          value: selectTransactionRow?.plan_id?.interval_time ? (
            selectTransactionRow?.plan_id?.interval_time
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Order",
          value: selectTransactionRow?.plan_id?.order ? (
            bgColor(selectTransactionRow?.plan_id?.order)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Pages Count",
          value: selectTransactionRow?.plan_id?.pages_count ? (
            bgColor(selectTransactionRow?.plan_id?.pages_count)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Pages Version Count",
          value: selectTransactionRow?.plan_id?.pages_version_count ? (
            bgColor(selectTransactionRow?.plan_id?.pages_version_count)
          ) : (
            <span>-</span>
          ),
        },

        {
          name: "Plan Id",
          value: selectTransactionRow?.plan_id?.plan_id ? (
            selectTransactionRow?.plan_id?.plan_id
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Product Id",
          value: selectTransactionRow?.plan_id?.product_id ? (
            selectTransactionRow?.plan_id?.product_id
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Product Name",
          value: selectTransactionRow?.plan_id?.product_name ? (
            selectTransactionRow?.plan_id?.product_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Assert Size",
          value:
            (selectTransactionRow?.plan_id?.assert_size / 1073741824).toFixed(
              0
            ) +
            "    GB " +
            "  " +
            "    -    " +
            (selectTransactionRow?.plan_id?.assert_size / 1048576).toFixed(0) +
            "  MB" +
            "  " +
            "    -    " +
            (selectTransactionRow?.plan_id?.assert_size / 1024).toFixed(0) +
            "   KB",
        },

        {
          name: "Projects Count",
          value: selectTransactionRow?.plan_id?.projects_count ? (
            bgColor(selectTransactionRow?.plan_id?.projects_count)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Projects Version Count",
          value: selectTransactionRow?.plan_id?.projects_version_count ? (
            bgColor(selectTransactionRow?.plan_id?.projects_version_count)
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Product Description",
          value: selectTransactionRow?.plan_id?.product_description ? (
            selectTransactionRow?.plan_id?.product_description
          ) : (
            <span>-</span>
          ),
        },

        {
          name: "Stripe Customer Id",
          value: selectTransactionRow.stripe_customer_id ? (
            selectTransactionRow.stripe_customer_id
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Stripe Subscription Id",
          value: selectTransactionRow.stripe_subscription_id ? (
            selectTransactionRow.stripe_subscription_id
          ) : (
            <span>-</span>
          ),
        },
        {
          name: " Subscription Id",
          value: selectTransactionRow.subscription_id ? (
            selectTransactionRow.subscription_id
          ) : (
            <span>-</span>
          ),
        },

        {
          name: "Created At",
          value: selectTransactionRow.createdAt ? (
            moment(selectTransactionRow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectTransactionRow.updatedAt ? (
            moment(selectTransactionRow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
      console.log(selectTransactionRow, "rowdataa");
    }
  }, [selectTransactionRow]);
  useEffect(() => {
    debounce(transaction_List);
  }, [searchTerm]);

  const handleResetFilters = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setSearchTerm("");
    // setIsFiltering(false);

    transaction_List();
    setFilters({
      start_date: "",
      end_date: "",
      status: "",
      search: "",
    });
  };

  const handleSearchMember = (e) => {
    const inputData = e.target.value;
    console.log(inputData);
  };

  const handleShowTransactionDetail = (dat) => {
    setSelectTransactionRow(dat.dat);
    setOpenTransactionDetail(true);
  };
  useEffect(() => {
    if (selectType == "all") {
      setFilterTransaction([]);
    } else if (selectType == "vission") {
      setFilterTransaction([3]);
    } else {
      setFilterTransaction([1, 2]);
    }
  }, [selectType]);

  useEffect(() => {
    if (user && user.admin_type === "sub_admin" && subAdminRole.roles) {
      let obj = getObjOfPrivileges("Transactions");
      setRolePrivilegesObj(obj);
      let _array = menuOptions;

      if (!obj.delete) {
        _array = [..._array.filter((option) => option.label != "Delete")];
      }

      setMenuOptions([..._array]);
    }
  }, [subAdminRole]);

  return (
    <>
      <DetailModal
        open={openTransactionDetail}
        setOpen={setOpenTransactionDetail}
        detail={transactionDetail}
      />
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Transactions
        </Typography>
        <Card sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              margin={2}
            >
              <FormControl sx={{ mb: 0, width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Choose Type"
                  value={selectType}
                  onChange={(e) => {
                    setIsFiltering(true);
                    setSelectType(e.target.value);
                  }}
                >
                  <MenuItem value={"innovate"}>Inovate</MenuItem>
                  <MenuItem value={"vission"}>Vission</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ mb: 0, width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={filters.status}
                  label="Status"
                  onChange={handleChangeFilters}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="payment_intent.payment_failed">
                    Rejected
                  </MenuItem>
                  <MenuItem value="payment_intent.succeeded">Succeed</MenuItem>
                  <MenuItem value="payment_intent.requires_action">
                    Pending
                  </MenuItem>
                  <MenuItem value="payment_intent.canceled">Cancel</MenuItem>
                  <MenuItem value="customer.subscription.deleted">
                    Delete
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="start-date"
                label="Start Date"
                name="start_date"
                type="date"
                defaultValue=""
                sx={{ mb: 2, width: "100%" }}
                value={moment(filters.start_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setIsFiltering(true);
                  // setIsFiltering(false)
                  setFilters({
                    ...filters,
                    start_date: moment(e.target.value).format("MM/DD/YYYY"),
                  });
                  // setIsFiltering(false)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className="d-flex align-items-center w-100">
                <TextField
                  id="end-date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  value={moment(filters.end_date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setIsFiltering(true);
                    setFilters({
                      ...filters,
                      end_date: moment(e.target.value).format("MM/DD/YYYY"),
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </Stack>
            <div className=" d-flex justify-content-between">
              <FormControl sx={{ mb: 0, width: "23.3%", mx: 2 }}>
                <InputLabel id="demo-simple-selec-label">
                  {/* Select Member */}
                </InputLabel>

                {/* <Autocomplete
                  id="combo-box-demo"
                  options={members}
                  value={filters.search}
                  onChange={(e, value) => {
                    setFilters({ ...filters, search: value });
                    value && setIsFiltering(true);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Member" />
                  )}
                /> */}

                <Typeahead
                  className="react-typeAhead"
                  placeholder="Select Member"
                  options={members}
                  value={filters.search}
                  onChange={(value) => {
                    setIsFiltering(true);
                    setFilters({ ...filters, search: value });
                  }}
                  selected={filters.search}
                />
              </FormControl>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
                margin={2}
                // marginRight={7}
              >
                <Button
                  variant="contained"
                  className="filter"
                  onClick={() => handleFilterClick()}
                  // disabled={isFiltering}
                  endIcon={<Icon icon="tdesign:filter" />}
                >
                  Filter
                </Button>

                <p
                  onClick={() => {
                    setPage(0);
                    handleClearFilterClick();
                  }}
                  className={
                    isFiltering == true
                      ? "showClearFilter"
                      : isFiltering == false
                      ? "clearFilter"
                      : ""
                  }
                  // disabled={!isFiltering}
                >
                  Clear Filter
                </p>
              </Stack>
            </div>
          </form>

          <TableContainer component={Paper}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      #
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Customer Name
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Customer Type
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Card
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      Card Brand
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Transaction Date
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Transaction Status
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Updated At
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Invoice URL
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Invoice PDF
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList.transaction && dataList.transaction.length > 0 ? (
                    dataList.transaction
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((dat, index) => (
                        <TableRow
                          key={dat.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                            onClick={() =>
                              handleShowTransactionDetail({ dat, index })
                            }
                          >
                            {dat.customer_name ? dat.customer_name : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            <Label
                              variant="ghost"
                              color="success"
                              className={
                                dat?.user_obj?.type == [3] ? "label" : ""
                              }
                            >
                              {dat?.user_obj?.type == [1]
                                ? "Inovate"
                                : dat?.user_obj?.type == [2]
                                ? "Inovate"
                                : dat?.user_obj?.type == [3]
                                ? "Vission"
                                : ""}
                            </Label>
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.amount ? dat.amount : "-"}
                          </TableCell>
                          <TableCell>{dat.currency.toUpperCase()}</TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.card ? dat.card : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.card_brand ? dat.card_brand : "-"}
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(dat.transaction_date).format("LLL")}
                          </TableCell>

                          <TableCell className="pl-0" align="center">
                            <Label
                              variant="ghost"
                              color={
                                (dat.transaction_status ===
                                  "payment_intent.succeeded" &&
                                  "success") ||
                                (dat.transaction_status ===
                                  "payment_intent.payment_failed" &&
                                  "error") ||
                                (dat.transaction_status ===
                                  "payment_intent.requires_action" &&
                                  "warning") ||
                                (dat.transaction_status ===
                                  "payment_intent.canceled" &&
                                  "warning") ||
                                (dat.transaction_status ===
                                "customer.subscription.deleted"
                                  ? "warning"
                                  : "default")
                              }
                            >
                              {dat.transaction_status ===
                              "payment_intent.succeeded"
                                ? "Succeed"
                                : dat.transaction_status ===
                                  "payment_intent.payment_failed"
                                ? "Rejected"
                                : dat.transaction_status ===
                                  "payment_intent.requires_action"
                                ? "Pending"
                                : dat.transaction_status ===
                                  "payment_intent.canceled"
                                ? "Canceled"
                                : dat.transaction_status ===
                                  "customer.subscription.deleted"
                                ? "Deleted"
                                : dat.transaction_status}
                            </Label>
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.createdAt
                              ? moment(dat.createdAt).format("LLL")
                              : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.updatedAt
                              ? moment(dat.updatedAt).format("LLL")
                              : "-"}
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={() =>
                                window.open(dat.hosted_invoice_url, "_blank")
                              }
                            >
                              preview
                            </Button>
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={() => window.open(dat.invoice_pdf)}
                            >
                              Download
                            </Button>
                          </TableCell>

                          {menuOptions.length > 0 && (
                            <TableCell align="right">
                              <CustomPopover
                                onClose={handleMenuClose}
                                data={dat}
                                menu={menuOptions}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                  ) : (
                    // : debouncedSearchTerm !== "" ? (
                    //   <TableRow>
                    //     <TableCell colSpan={12} align="center">
                    //       No Search Data Found
                    //     </TableCell>
                    //   </TableRow>
                    // )
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        No Transaction Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataList.transaction ? dataList.total_transaction : 0}
            rowsPerPage={rowsPerPage && rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Card>
      </Box>
    </>
  );
}
