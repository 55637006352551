import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
// import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";

export default function TextModal({
  open,
  setOpen,
  item,
  setItem,
  data,
  setData,
  textIndex,
}) {
  console.log(data, "gsaufuytfs");
  const [text, setText] = useState({
    text: "",
  });

  const handleClose = () => {
    setData({ text: "" });
    setOpen(false);
  };

  const handleChange = (e) => {
    setText((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    if(data){
      setText(data);
    }
  }, [data]);

  const handleAddItem = (e) => {
    e.preventDefault();
    let array = item.banner_text_h2;
    array.push(text);

    setItem({ ...item, banner_text_h2: array });
    handleClose();
  };

  const HandleEditData = (e) => {
    e.preventDefault();
    let array = item.banner_text_h2;
    array[textIndex] = text;

    setItem({ ...item, banner_text_h2: array });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>{data.text ? "Edit Text" : " Add Text"}</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form className="mt-3">
                  <TextField
                    label="Banner text h2"
                    required
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange(e)}
                    value={text?.text}
                    name="text"
                  />
                  {data.text ? (
                    <>
                      <div className="text-end mt-3">
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={(e) => HandleEditData(e)}
                        >
                          Edit
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-end mt-3">
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={(e) => handleAddItem(e)}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
