import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Label from "../../components/Label";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConfirmation from "src/components/CustomConfirmation";
import {
  _delete_page,
  _page_group_list,
  _project_details_listing,
} from "src/DAL/ProjectDetails/projectdetails";
import { useLocation, useParams } from "react-router-dom";

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { useSnackbar } from "notistack";
import { get_short_string } from "src/utils/utils";
import PagesSection from "./PagesDetailSection";
import FunelPagesModal from "./FunelPagesModel";

export default function PagesDetailList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  console.log(data, "dataaaaaaaa");
  const location = useLocation();
  const rowData = location?.state;
  console.log(rowData, "datattatat");
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true); // Loading state

  const [showData, setShowdata] = useState(false);
  const [showFunnelPages, setShowFunnelPages] = useState(false);

  const [funelPageRecord, setFunelPageRecord] = useState([]);
  const [funelPageRecordModel, setFunelPageRecordModel] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  // const [selectedRow, setSelectedRow] = useState({});

  const pageDetailList = async () => {
    const project_list_response = await _project_details_listing(id);
    console.log(project_list_response, "project_list_response");
    setData(project_list_response.project);
    setLoading(false);
  };

  useEffect(() => {
    pageDetailList();
  }, [id]);

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeletePage = async () => {
    if (selectedPage.page_slug === "index") {
      handleMenuClose();
      enqueueSnackbar("Cannot delete the index page", { variant: "error" });

      return;
    }

    const delete_resp = await _delete_page(selectedPage._id);

    if (delete_resp.code == 200) {
      setOpenDelete(false);
      pageDetailList();
      enqueueSnackbar("Page Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }

    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
  };

  const handleShowFunelData = () => {
    setShowdata(true);
    setShowFunnelPages(false);
  };

  const handleShowAllData = () => {
    setShowdata(false);
  };

  const handleShowFunelPages = async (id) => {
    const page_group_detail = await _page_group_list(id);
    console.log(page_group_detail, "responseoffunneldetail");
    if (page_group_detail.code === 200) {
      setFunelPageRecord(page_group_detail.page_group_pages_list);
      setFunelPageRecordModel(true);
    }
  };

  return (
    <>
      <FunelPagesModal
        open={funelPageRecordModel}
        setOpen={setFunelPageRecordModel}
        detail={funelPageRecord}
        payment_id={data?.Project?.payment_page_id}
      />
      <TableContainer component={Paper}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <Typography variant="h5" sx={{ py: 1 }}>
                {showData === false
                  ? "Pages List"
                  : showData === true
                  ? "Funels List"
                  : ""}
              </Typography>

              <div className="d-flex align-items-center gap-4  ">
                <Label
                  sx={{ width: 70 }}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowAllData()}
                  className={showData === false ? "labelColor" : ""}
                >
                  All
                </Label>

                <Label
                  sx={{ width: 70 }}
                  style={{ cursor: "pointer",gap:"3px" }}
                  onClick={() => handleShowFunelData()}
                  className={showData === true ? "labelColor" : ""}
                >
                  Funel <span>({data.pages_group.length})</span> 
                </Label>
              </div>
            </div>

            {showData === false ? (
              <>
                <PagesSection
                  data={data.pages}
                  payment_id={data?.Project?.payment_page_id}
                />
              </>
            ) : (
              <>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell></TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Name
                      </TableCell>

                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Page Group Type
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Pages Number
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Show Funel Pages
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Created At
                      </TableCell>

                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Update At
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.pages_group && data.pages_group.length > 0 ? (
                      data.pages_group.map((dat, index) => (
                        <TableRow
                          key={dat.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell>
                            <IconButton
                              aria-label="more"
                              aria-controls="page-menu"
                              aria-haspopup="true"
                              onClick={(e) => handleMenuOpen(e, dat)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="page-menu"
                              anchorEl={anchorEl}
                              open={Boolean(
                                anchorEl &&
                                  selectedPage &&
                                  selectedPage.id === dat.id
                              )}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={handleDeleteClick}>
                                Delete
                              </MenuItem>
                            </Menu>
                          </TableCell>

                          <TableCell>
                            <span style={{ cursor: "pointer" }}>
                              {dat.name && get_short_string(dat.name, 20)}
                            </span>
                          </TableCell>

                          <TableCell>
                            {dat.page_group_type ? dat.page_group_type : "-"}
                          </TableCell>

                          <TableCell>{dat.pages_number}</TableCell>

                          <TableCell>
                            <Button
                              onClick={() => handleShowFunelPages(dat._id)}
                            >
                              Show Pages
                            </Button>
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(dat.createdAt).format("LLL")}
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(dat.updatedAt).format("LLL")}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          No Funel List Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        )}
      </TableContainer>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this customer?"}
        handleAgree={handleDeletePage}
      />
    </>
  );
}
