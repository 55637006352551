import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import NeedSectionModal from "../NeedSectionModel/NeedSectionModel";
import { _everything_you_need } from "src/DAL/Website/EveryThingYouNeed";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/CustomConfirmation";

export default function EveryThingYouNeed() {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [input, setInput] = useState({
    title: "",
  });

  const [menuItemDataObj, setMenuItemDataObj] = useState({});

  const handleChange = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        title: e.target.value,
      };
    });
  };

  const handleAddMenuData = () => {
    setOpen(true);
  };

  const GetEveryThingNeedDetail = async () => {
    setLoading(true);
    const resp = await _landing_page_detail();
    if (resp.code === 200) {
      setLoading(false);
      console.log(resp?.data?.everything_you_need, "yasfdstyfytsc");
      setMenuItemDataObj(resp?.data?.everything_you_need);
      setInput(resp?.data?.everything_you_need);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let tempMenuItemsArr = [...menuItemDataObj?.menu_items];

    for (let i = 0; i < tempMenuItemsArr.length; i++) {
      if (tempMenuItemsArr[i]._id) {
        delete tempMenuItemsArr[i]._id;
      }

      if (
        typeof tempMenuItemsArr[i]?.image == "string" &&
        tempMenuItemsArr[i]?.image !== ""
      ) {
        continue;
      }
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", tempMenuItemsArr[i]?.image);

      const image_resp = await _upload_file_on_s3(formData);

      if (image_resp.code === 200) {
        tempMenuItemsArr[i].image = image_resp.file_name;
      } else {
        tempMenuItemsArr[i].image = "";
      }

      delete tempMenuItemsArr[i].local_image;
    }

    const postData = {
      title: input?.title,
      menu_items: [...tempMenuItemsArr],
    };

    const result = await _everything_you_need(postData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      GetEveryThingNeedDetail();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleConfirmDelete = (i) => {
    setMenuItemDataObj((prev) => {
      return {
        ...prev,
        menu_items: prev.menu_items.filter((elem, index) => index !== i),
      };
    });
    setOpenDelete(false);
  };

  const handleDelete = (i) => {
    setSelectedIndex(i);
    setOpenDelete(true);
  };

  const handleEdit = (row, i) => {
    console.log(row, "rtadfatsdf", i);
    setSelectedData(row);
    setSelectedIndex(i);
    setOpen(true);
  };

  useEffect(() => {
    GetEveryThingNeedDetail();
  }, []);

  console.log(selectedIndex,"tysfdtyf")
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div style={{ width: "94%", margin: "auto" }}>
            <NeedSectionModal
              open={open}
              setOpen={setOpen}
              menuItemDataObj={menuItemDataObj}
              setMenuItemDataObj={setMenuItemDataObj}
              selectedData={selectedData}
              selectedIndex={selectedIndex}
              setSelectedData={setSelectedData}
            />
            <div>
              <h3 className="text-center mt-5">Every Thing You Need</h3>
              <div className="mt-2">
                <p>Main Title</p>
                <GeneralCkeditor
                  inputs={input}
                  setInputs={setInput}
                  value={input?.title}
                  name="title"
                  onChange={handleChange}
                />
              </div>
              <div>
                <Button onClick={handleAddMenuData} className="mt-3">
                  Add Menu Items Data
                </Button>
              </div>
            </div>

            <div>
              <Table className="mt-3">
                <TableHead>
                  <TableRow>
                    <TableCell>Menu Title</TableCell>
                    <TableCell>Image</TableCell>
                    {/* <TableCell>Description</TableCell> */}
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {menuItemDataObj?.menu_items?.map((val, i) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell><div
                              dangerouslySetInnerHTML={{
                                __html: val?.title,
                              }}
                            /></TableCell>
                          <TableCell>
                            <img
                              style={{ width: "4rem", height: "2.5rem" }}
                              src={
                                val.local_image
                                  ? val.local_image
                                  : s3baseUrl + "/" + val.image
                              }
                            />
                          </TableCell>
                          {/* <TableCell>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: val?.description,
                              }}
                            />
                          </TableCell> */}
                          <TableCell>
                            <Icon
                              onClick={() => handleEdit(val, i)}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Icon
                              onClick={() => handleDelete(i)}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="text-end mt-3">
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </div>

            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are You sure you want to Delete ?"}
              handleAgree={() => handleConfirmDelete(selectedIndex)}
            />
          </div>
        </>
      )}
    </>
  );
}
