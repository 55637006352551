import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";

export default function FooterModal({
  open,
  setOpen,
  data,
  setData,
menuInputsObj,
setMenuInputsObj,
selectedIndex
}) {
  const [menuItems, setMenuItems] = useState({
    title: "",
    link: "",
  });

  const handleClose = () => {
    setOpen(false);
    setData({ title: "", link: "" });
  };

  const handleChange = (e) => {
    setMenuItems((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    if(data){
      setMenuItems(data);
    }
  
  }, [data]);

  const handleAddItem = (e) => {
    e.preventDefault();
    let array = menuInputsObj.menu_items;
    array.push(menuItems);
    setMenuInputsObj({ ...menuInputsObj, menu_items: array });
    handleClose();
  };

  const HandleEditData = (e) => {
    e.preventDefault();
    let array = menuInputsObj.menu_items;
    array[selectedIndex] = menuItems;

    setMenuInputsObj({ ...menuInputsObj, menu_items: array });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {data?.title || data?.link
                ? "Edit Footer Menu Items"
                : "Add Footer Menu Items"}
            </p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form className="gap-3 mt-3">
                  <TextField
                    label="title"
                    required
                    style={{ width: "48%" }}
                    onChange={(e) => handleChange(e)}
                    value={menuItems?.title}
                    name="title"
                  />
                  <TextField
                    label="link"
                    required
                    style={{ width: "48%", marginLeft: "15px" }}
                    onChange={(e) => handleChange(e)}
                    value={menuItems?.link}
                    name="link"
                  />
                  {data?.title || data?.link ? (
                    <>
                      <div className="text-end mt-3">
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={(e) => HandleEditData(e)}
                        >
                          Edit
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-end mt-3">
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={(e) => handleAddItem(e)}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
