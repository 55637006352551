import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../components/_dashboard/user";
//
import USERLIST from "../../../_mocks_/user";
import {
  _customer_list,
  _delete_customer,
  _get_customer_token_for_login,
} from "src/DAL/customer/customer";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import { ProductFilterSidebar } from "src/components/_dashboard/products";
import UpdateHelpingCategory from "../Update-Support-Tickets/UpdateSupportDepartment";
import {
  _delete_helping_category,
  _helping_categories_listing,
} from "src/DAL/helping-categories/helping_categories";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import {
  _delete_department,
  _departments_listing,
} from "src/DAL/support_departments/support_departments";
import {
  _close_support_ticket,
  _delete_support_ticket,
  _support_tickets_listing,
} from "src/DAL/support_tickets/support_tickets";
import { get_short_string } from "src/utils/utils";
import AddSupportTickets from "../AddSupportTicket/AddSupportTicket";
import { useAppContext } from "src/Hooks/AppContext";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "subject", label: "Subject", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "department", label: "Category", alignRight: false },
  { id: "customer", label: "Customer", alignRight: false },
  { id: "comment_badge_count", label: "Messages", alignRight: false },
  { id: "response_status", label: "Response Status", alignRight: false },
  { id: "ticket_status", label: "Ticket Status", alignRight: false },
  { id: "last_action_date", label: "Last Activity", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.subject.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const tabs = {
  all: "all",
  open: "open",
  close: "closed",
  trash: "trash",
};

export default function CustomerListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { setOpenCount, user, getObjOfPrivileges, subAdminRole } =
  useAppContext();
  const [rolePrivilegesObj, setRolePrivilegesObj] = useState({});

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  console.log(data, "dataaa");
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenAdd, setIsDrawerOpenAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [ticketsData, setTicketsData] = useState("");
  const [selectedTab, setSelectedTab] = useState(tabs.open);



  const CheckActivityTime = (last_action_date) => {
    var No_Response_Threshold = new Date();
    No_Response_Threshold.setDate(No_Response_Threshold.getDate() - 7);
    var last_action = new Date(last_action_date);

    if (
      No_Response_Threshold.getTime() >= last_action.getTime(last_action_date)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleOpenChangePassword = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseChangePassword = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenAdd = () => {
    setIsDrawerOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setIsDrawerOpenAdd(false);
  };

  const handleDelete = async () => {
    console.log(selectedRow);
    const delete_resp = await _delete_support_ticket(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      get_listing(page, rowsPerPage);
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleComplete = async () => {
    console.log(selectedRow);
    const delete_resp = await _close_support_ticket(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenComplete(false);
      get_listing(page, rowsPerPage);
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = () => {
    return ticketsData[selectedTab];
    // return applySortFilter(
    //   ticketsData[selectedTab] ? ticketsData[selectedTab] : [],
    //   getComparator(order, orderBy),
    //   filterName
    // );
  };

  const isUserNotFound = filteredUsers()?.length === 0;

  const get_listing = async (is_all = false) => {
    const postData = {
      search: filterName,
      status: selectedTab,
    };
    const resp = await _support_tickets_listing(page, rowsPerPage, postData);
    console.log(resp, "respppppp");
    if (resp.code == 200) {
      if (selectedTab === tabs.all) {
        setTotalPages(resp.support_ticket_count);
      } else if (selectedTab === tabs.open) {
        setTotalPages(resp.open_ticket_count);
      } else if (selectedTab === tabs.close) {
        setTotalPages(resp.close_ticket_count);
      }
      setTicketsData(resp);
      setData(resp.support_ticket);
      setOpenCount(
        resp.support_ticket.filter((val) => val.response_status === 0)
      );
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleUpdatePasswordClick = (row) => {
    setSelectedRow(row);
    handleOpenChangePassword();
  };
  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleCompleteClick = (row) => {
    setSelectedRow(row);
    setOpenComplete(true);
  };

  const handleRowClick = (row) => {
    navigate("/support-tickets/" + row._id);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "View Details",
      icon: "carbon:view",
      handleClick: handleRowClick,
    },
    {
      label: "Close Ticket",
      icon: "carbon:task-complete",
      handleClick: handleCompleteClick,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ]);

  const MENU_OPTIONS = (row) => {
    let _array = menuOptions;
    if (
      (user.admin_type === "sub_admin" && !rolePrivilegesObj?.edit) ||
      row.ticket_status != 0
    ) {
      _array = [..._array.filter((option) => option.label != "Close Ticket")];
    }
    if (user.admin_type === "sub_admin" && !rolePrivilegesObj?.delete) {
      _array = [..._array.filter((option) => option.label != "Delete")];
    }

    return _array;
  };

  const handleRefresh = () => {
    get_listing();
  };

  useEffect(() => {
    get_listing();
  }, [selectedTab, page, rowsPerPage, filterName]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(50);
    setIsLoading(true);
  }, [selectedTab]);

  useEffect(() => {
    if (user && user.admin_type === "sub_admin" && subAdminRole.roles) {
      let obj = getObjOfPrivileges("Support Tickets");
      setRolePrivilegesObj(obj);
    }
  }, [subAdminRole]);

  if (isLoading) {
    return (
      <>
        <div className="container">
          {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
          >
            <Typography variant="h4" gutterBottom>
              Support Tickets
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-start"
            mb={3}
          >
            <div className="me-2 pointer">
              <Label
                onClick={() => setSelectedTab(tabs.open)}
                variant={selectedTab === tabs.open ? "ghost" : ""}
                style={{ width: 100, cursor: "pointer" }}
                color="info"
              >
                OPEN ({ticketsData.open_ticket_count})
              </Label>
            </div>

            <div className="me-2 pointer">
              <Label
                onClick={() => setSelectedTab(tabs.close)}
                variant={selectedTab === tabs.close ? "ghost" : ""}
                style={{ width: 100, cursor: "pointer" }}
                color="success"
              >
                CLOSED ({ticketsData.close_ticket_count})
              </Label>
            </div>
            <div className="me-2 pointer">
              <Label
                onClick={() => setSelectedTab(tabs.all)}
                variant={selectedTab === tabs.all ? "filled" : ""}
                style={{ width: 100, cursor: "pointer" }}
                color="info"
              >
                ALL
              </Label>
            </div>
          </Stack>
        </div>
        <Loader />
      </>
    );
  }

  return (
    <>
      <div className="container">
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Support Tickets
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <div className="d-flex">
            <div className="me-2 pointer">
              <Label
                onClick={() => setSelectedTab(tabs.open)}
                variant={selectedTab === tabs.open ? "ghost" : ""}
                style={{ width: 100, cursor: "pointer" }}
                color="info"
              >
                OPEN ({ticketsData.open_ticket_count})
              </Label>
            </div>

            <div className="me-2 pointer">
              <Label
                onClick={() => setSelectedTab(tabs.close)}
                variant={selectedTab === tabs.close ? "ghost" : ""}
                style={{ width: 100, cursor: "pointer" }}
                color="success"
              >
                CLOSED ({ticketsData.close_ticket_count})
              </Label>
            </div>
            <div className="me-2 pointer">
              <Label
                onClick={() => setSelectedTab(tabs.all)}
                variant={selectedTab === tabs.all ? "filled" : ""}
                style={{ width: 100, cursor: "pointer" }}
                color="info"
              >
                ALL
              </Label>
            </div>
          </div>
          {/* <Button
            className=""
            onClick={handleOpenAdd}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Create Support Ticket
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {data
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {
                        _id,
                        subject,
                        description,
                        ticket_status,
                        response_status,
                        last_action_date,
                        comment_badge_count,
                        status,
                        department,
                        createdAt,
                        user_id,
                      } = row;
                      console.log(row);

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>{i + 1}</TableCell>
                          <TableCell
                            className="pointer"
                            onClick={() => handleRowClick(row)}
                            component="th"
                            scope="row"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                className="text-capitalize"
                                variant="subtitle2"
                                noWrap
                              >
                                {subject
                                  ? subject && get_short_string(subject, 30)
                                  : "-"}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell className="pl-0" align="left">
                            {description
                              ? description && get_short_string(description)
                              : "-"}
                          </TableCell>

                          <TableCell className="pl-0" align="left">
                            {department
                              ? department &&
                                get_short_string(department?.title)
                              : "-"}
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            {user_id.first_name + " " + user_id.last_name
                              ? user_id.first_name + " " + user_id.last_name
                              : "-"}
                          </TableCell>

                          <TableCell align="center" className="pl-0">
                            <Label variant="filled" color="warning">
                              {comment_badge_count ? comment_badge_count : "-"}
                            </Label>
                          </TableCell>

                          <TableCell className="pl-0" align="center">
                            {response_status === 0 && (
                              <Label
                                style={{ width: 70 }}
                                variant="ghost"
                                color="warning"
                              >
                                Waiting
                              </Label>
                            )}

                            {response_status === 1 && (
                              <Label
                                style={{ width: 70 }}
                                variant="ghost"
                                color="success"
                              >
                                Replied
                              </Label>
                            )}
                          </TableCell>

                          <TableCell className="pl-0" align="center">
                            {!CheckActivityTime(last_action_date) &&
                              ticket_status === 0 && (
                                <Label
                                  style={{ width: 70 }}
                                  variant="ghost"
                                  color="info"
                                >
                                  OPEN
                                </Label>
                              )}

                            {ticket_status === 1 && (
                              <Label
                                style={{ width: 70 }}
                                variant="ghost"
                                color="success"
                              >
                                CLOSED
                              </Label>
                            )}
                            {CheckActivityTime(last_action_date) &&
                              ticket_status === 0 && (
                                <Label
                                  // style={{ width: 110 }}
                                  variant="ghost"
                                  color="error"
                                >
                                  Not Responding
                                </Label>
                              )}
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            {last_action_date
                              ? moment(last_action_date).fromNow()
                              : "-"}
                          </TableCell>

                          <TableCell className="pl-0" align="left">
                            {createdAt ? moment(createdAt).format("LLL") : "-"}
                          </TableCell>

                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={status ? "success" : "error"}
                            >
                              {status ? "Active" : "Inactive"}
                            </Label>
                          </TableCell>

                          {/* <TableCell className="pl-0" align="left">
                            {moment(createdAt).format("LLL")}
                          </TableCell> */}

                          <TableCell align="right">
                            <CustomPopover
                              data={row}
                              menu={MENU_OPTIONS(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {isUserNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this support ticket?"}
              handleAgree={handleDelete}
            />
          </>

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>

      <CustomConfirmation
        open={openComplete}
        setOpen={setOpenComplete}
        title={"Are you sure you want to close this Support Ticket?"}
        handleAgree={handleComplete}
      />

      <AddSupportTickets
        isOpenDrawer={isDrawerOpenAdd}
        onOpenDrawer={handleOpenAdd}
        onCloseDrawer={handleCloseAdd}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
