import {
  Box,
  Button,
  CircularProgress,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import FAQModal from "../FAQDialog/FAQ-Dialog";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { Icon } from "@iconify/react";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { _question_answer } from "src/DAL/Website/FAQSetion";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/CustomConfirmation";

const FAQ = () => {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    image: "",
    button: {
      text: "",
      link: "",
    },
  });

  const [menuInputsObj, setMenuInputsObj] = useState({});

  console.log(menuInputsObj, "dartdastfdystad");

  const handleChange = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        title: e.target.value,
      };
    });
  };

  const handleImageUpload = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        local_image: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      };
    });
  };

  const GetFAQDetail = async () => {
    setLoading(true);
    const FAQData = await _landing_page_detail();
    if (FAQData.code === 200) {
      setLoading(false);
      setMenuInputsObj(FAQData.data.faq);
      setInputs(FAQData.data.faq);
    }
  };

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleEdit = (row, i) => {
    setSelectedData(row);
    setSelectedIndex(i);
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newArr = [...menuInputsObj.question_items];

    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i].state === false || newArr[i].state === true) {
        delete newArr[i].state;
      }
      if (newArr[i]._id) {
        delete newArr[i]._id;
      }
    }

    if (typeof inputs.image === "string") {
      const postData = {
        title: inputs?.title,
        image: inputs?.image,
        button: {
          text: inputs?.button?.text,
          link: inputs?.button?.link,
        },
        question_items: [...newArr],
      };

      const response = await _question_answer(postData);
      if (response.code === 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        GetFAQDetail();
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } else {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs?.image);

      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code === 200) {
        delete inputs.local_image;
        const postData = {
          title: inputs?.title,
          image: image_resp.file_name,
          button: {
            text: inputs?.button?.text,
            link: inputs?.button?.link,
          },
          question_items: [...newArr],
        };

        // console.log(postData,"syatyfytfadt")

        const response = await _question_answer(postData);
        if (response.code === 200) {
          enqueueSnackbar(response.message, { variant: "success" });
          GetFAQDetail();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      }
    }
  };

  const handleDelete = (i) => {
    setSelectedIndex(i);
    setOpenDelete(true);
  };

  const handleConfirmDelete = (i) => {
    setMenuInputsObj((prev) => {
      return {
        ...prev,
        question_items: prev.question_items.filter((ele, index) => index !== i),
      };
    });
    setOpenDelete(false);
  };

  useEffect(() => {
    GetFAQDetail();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <FAQModal
            open={open}
            setOpen={setOpen}
            menuInputsObj={menuInputsObj}
            setMenuInputsObj={setMenuInputsObj}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            selectedIndex={selectedIndex}
          />
          <form>
          <div style={{ width: "94%", margin: "auto" }}>
            <h3 className="text-center mt-3">FAQ's Section</h3>
            <div className="mt-5">
              <p>Title</p>
              <GeneralCkeditor
                inputs={inputs}
                setInputs={setInputs}
                value={inputs.title}
                onChange={handleChange}
                name="title"
              />
            </div>
            <div className="col-12 d-flex gap-3 mt-3">
              <TextField
                label="Text"
                required
                style={{ width: "40%" }}
                value={inputs?.button?.text}
                onChange={(e) =>
                  setInputs((prev) => {
                    return {
                      ...prev,
                      button: {
                        ...prev.button,
                        text: e.target.value,
                      },
                    };
                  })
                }
              />
              <TextField
                label="link"
                required
                style={{ width: "40%" }}
                value={inputs?.button?.link}
                onChange={(e) =>
                  setInputs((prev) => {
                    return {
                      ...prev,
                      button: {
                        ...prev.button,
                        link: e.target.value,
                      },
                    };
                  })
                }
              />
            </div>
            <div className="mt-3">
              <Button onClick={handleOpenMenu}>Add Questions</Button>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center mt-3">
              <img
                style={{ width: "16rem", height: "8rem" }}
                src={
                  inputs?.local_image
                    ? inputs?.local_image
                    : s3baseUrl + "/" + inputs?.image
                }
                alt=""
              />
              <label htmlFor="icon-button-file">
                <Input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  name="file"
                  // onChange={handleBrandLogoUpload}
                  onChange={handleImageUpload}
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3"
                >
                  {inputs.image ? "Change Image" : "Choose Image"}
                </Button>
              </label>
            </div>
          </div>

          <div style={{ width: "94%", margin: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Menu Title</TableCell>
                  <TableCell>Menu Description</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menuInputsObj.question_items?.map((val, i) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: val?.title,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: val?.description,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Icon
                            onClick={() => handleEdit(val, i)}
                            style={{ color: "green", cursor: "pointer" }}
                            icon={"fa-regular:edit"}
                          />
                        </TableCell>
                        <TableCell>
                          <Icon
                            onClick={() => handleDelete(i)}
                            style={{ color: "red", cursor: "pointer" }}
                            icon={"ic:baseline-delete"}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </div>

          <div className="text-end mt-3">
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          </form>
          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are You sure you want to Delete ?"}
            handleAgree={() => handleConfirmDelete(selectedIndex)}
          />
        </>
      )}
    </>
  );
};

export default FAQ;
